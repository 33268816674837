import { render, staticRenderFns } from "./EmailLogin.vue?vue&type=template&id=0656c6d6&scoped=true&"
import script from "./EmailLogin.vue?vue&type=script&lang=js&"
export * from "./EmailLogin.vue?vue&type=script&lang=js&"
import style0 from "./EmailLogin.vue?vue&type=style&index=0&id=0656c6d6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0656c6d6",
  null
  
)

export default component.exports